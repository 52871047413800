.genre-list-page {
  margin: 20px auto;
  padding: 20px 20px 125px 20px;
  max-width: 800px;
  font-family: Arial, sans-serif;
}

/*********************************
 Disabled, it is in Searchbar.css
 ********************************/
/*
.search-bar {
  margin: 20px auto;
  text-align: center;
}

.search-bar input {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}
*/

.genre-grid,
.station-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.genre-card,
.station-card {
  /* background-color: #f9f9f9; */
  background-color: #00496C;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
border: 1px solid #ddd;
}

.genre-card:hover,
.station-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.genre-card img,
.station-card img {
  width: 100%;
  height: auto;
  /* max-height: 150px; */
  max-height: 350px;
  border-radius: 8px;
  /* object-fit: cover; */
  object-fit: contain;
  display: block;
  margin-bottom: 10px;
}

.genre-card h3 {
  font-size: 1.2rem;
  /* color: #007acc; */
  color: #FFFFFF; 
}

.genre-card p {
  font-size: 0.9rem;
  /* color: #007acc; */ 
  color: #FFFFFF; 
}

.station-card h3 {
  font-size: 1.2rem;
  color: #007acc;
  /* color: #FFFFFF; */
}

.station-card p {
  font-size: 0.9rem;
  color: #007acc;  
  /* color: #FFFFFF; */
}

.station-card button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  background-color: #007acc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.station-card button:hover {
  background-color: #005fa3;
}

