:root {
  /* Color Palette */
  --new-background-color: #023; /* Very Dark Blue (replced in 3 areas) */
  --background-color: #212121; /* Dark gray */
  --section-background-color: #113F4B; /* Dark teal */
  --secondary-background-color: #304D6F; /* Muted blue */
  --text-color: #FFFFFF; /* White */
  --muted-text-color: #CCCCCC; /* Light gray */
  --primary-accent-color: #00496C; /* Bold teal */
  --secondary-accent-color: #1FAAF0; /* Vibrant blue */
  --hover-color: #147BB5; /* Darker blue for hover states */

  /* Typography */
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Arial', sans-serif;
  --font-size-base: 16px;
  --font-size-small: 14px;
  --font-size-large: 24px;

  /* Spacing */
  --spacing-custom1: 12px;
  --spacing-small: 10px;
  --spacing-medium: 20px;
  --spacing-large: 40px;

  /* Borders */
  --border-radius: 5px;
  --border-color: #444444;

  /* Shadows */
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  --box-shadow-hover: 0 6px 12px rgba(0, 0, 0, 0.3);
}

