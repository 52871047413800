.donate-page {
  text-align: center;
  padding: 20px;
  margin: 20px auto;
  border: 1px solid #ccc; /* 1px border */
  border-radius: 8px; /* Rounded corners */
  max-width: 750px; /* Optional: Limit the width of the box */
  /* background-color: #fff; */ /* Optional: Add a white background */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}


.facebook-banner {
  background-color: #000;
  padding: 10px;
}

.facebook-banner p {
  color: yellow;
  margin: 0;
}

h2 {
  color: #3AC;
  /* color: #00496C; */
  margin: 20px 0;
}

hr {
  border: 1px solid #ccc;
  margin: 40px 0;
}
