/*********************************
 Disabled, it is in Searchbar.css
 ********************************/
/*
.search-box {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 20px auto;
  display: block;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-box:focus {
  outline: none;
  border-color: #007acc;
  box-shadow: 0 0 5px rgba(0, 122, 204, 0.5);
}
*/


.links-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 780px;
  /* max-width: 1200px; */
  margin: 0 auto;
  line-height: 1.6;
}

.links-page h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
  /* color: #333; */
  color: #FFFFFF;
}

.links-page p {
  text-align: center;
  margin-bottom: 30px;
  /* color: #555; */
  color: #FFFFFF;
}

.genre-section-container {
  margin-top: 20px;
}

.genre-section {
  margin-bottom: 40px;
  padding: 20px;
  /* background-color: #f9f9f9; */
  background-color: #00496C;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.genre-title {
  font-size: 1.8rem;
  /* color: #007acc; */
  color: #FFFFFF;
  margin-bottom: 20px;
}

.station-list {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding-bottom: 125px;
  padding-top: 30px;
}

.station-card {
  background-color: #00496C;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.station-card h3 {
  color: #FFF;
}

.station-card p {
  color: #FFF;
}

.station-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.station-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 50%;
}

.station-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.station-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.stream-url {
  font-size: 0.9rem;
  color: #FFF;
  word-wrap: break-word;
}

.listen-now-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007acc;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.listen-now-button:hover {
  background-color: #005fa3;
}

