/* ==========================
   General Styles & Variables
   ========================== */
:root {
  --primary-color: #333;
  --primary-accent-color: #444;
  --text-color: white;
  --hover-color: #555;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  display:flex;
  flex-direction: column;
/*
  justify-content: center; 
  background-color: #f0f0f0;
*/
  font-family: Arial, sans-serif;
  /* background-color: var(--new-background-color); */ 
  background-color: var(--primary-color); 
  color: var(--text-color);
}

/* not used */
.wrapper {
  width: 800px; /* Set the fixed width of the page */
  min-height: 100vh; /* Ensure it fills the full height of the viewport */
  border-left: 1px solid #ccc; /* Add a 1px border on the left */
  border-right: 1px solid #ccc; /* Add a 1px border on the right */
  background-color: #fff; /* Optional: Background inside the page */
  display: flex;
  flex-direction: column;
}

main {
  flex: 1; /* Ensure main content stretches only if needed */
  padding: 20px;
}

/* The main app wrapper */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
border: solid #CCC;
border-width: 1px 1px 1px 1px;

  /* border-radius: 8px; */
  background-clip: padding-box;
overflow: hidden;
  margin: 0 auto; /* Center the container horizontally */
  max-width: 800px; /* Optional: Set a maximum width if desired */
  background-color: var(--new-background-color); /* Ensure a background color is set */
}

/* The Routes and content */
.app > *:not(footer) {
  flex-grow: 0; /* Allow the main content to grow and push the footer down */
}

/* Footer styling */
footer {
  background-color: var(--primary-color);
  /* background-color: #000; */	 /* Black background for visibility */ 
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  margin-top: auto;
  width: 100%;
}


a {
  text-decoration: none;
  color: var(--text-color);
}

a:hover {
  color: var(--hover-color);
}

/* ==========================
   Header Styles
   ========================== */
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--new-background-color);
  padding: 20px 20px 0px 20px;
}

.logo-container {
  text-align: center;
  margin-bottom: 10px;
}

.logo {
  max-width: 250px;
  height: auto;
  margin-bottom: 10px;
  filter: drop-shadow(0 0 30px ##002266); /* Color of outline (glow) around the logo */
}


/* ==============================
   Recent Played Dropdown  Styles
   ============================== */

/* Custom Dropdown Container */
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 300px; /* Set the dropdown width */
}

/* Dropdown Toggle Button */
.dropdown-toggle {
  width: 100%; /* Matches the dropdown container width */
  background-color: var(--primary-accent-color);
  color: var(--text-color);
  border: 1px solid var(--hover-color);
  border-radius: var(--border-radius);
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dropdown-toggle:hover {
  background-color: var(--secondary-accent-color);
}

/* Dropdown Menu */
.dropdown-menu {
  width: 100%; /* Matches the parent width */
  box-sizing: border-box; /* Ensures padding/borders are included in the width */
  position: absolute;
  background-color: var(--section-background-color);
  border: 1px solid var(--hover-color);
  border-radius: var(--border-radius);
  margin-top: 5px;
  box-shadow: var(--box-shadow);
  padding: 10px 0; /* Adjust padding if needed */
  z-index: 1000;
  max-height: 300px; /* Constrain height with scrolling */
  overflow-y: auto; /* Add scrolling if menu overflows */
}

/* Genre (Disabled Options) */
.dropdown-menu .dropdown-genre {
  font-weight: bold; /* Make genre text bold */
  color: var(--muted-text-color); /* Use muted text color */
  background-color: var(--secondary-background-color); /* Subtle background for genre headers */
  padding: 10px; /* Add spacing for readability */
  text-transform: uppercase; /* Optional: Make it uppercase for emphasis */
  cursor: default; /* Show that it’s not clickable */
}

/* Selectable Items */
.dropdown-menu .dropdown-item {
  padding: 10px; /* Regular options padding */
  color: var(--text-color); /* Default text color */
  cursor: pointer; /* Pointer cursor for selectable items */
  transition: background-color 0.2s ease-in-out;
}

.dropdown-menu .dropdown-item:hover {
  background-color: var(--hover-color); /* Highlight on hover */
}

/* Optional: Scrollbar Styling (Modern Browsers) */
.dropdown-menu::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: var(--hover-color); /* Scrollbar color */
  border-radius: 10px; /* Round edges */
}

.dropdown-menu::-webkit-scrollbar-track {
  background: var(--section-background-color); /* Scrollbar track background */
}


/* =================================
   End Recent Played Dropdown Styles
   ================================= */





/* ==========================
   Navbar Styles
   ========================== */
.navbar {
  display: flex;
  justify-content: center;
  /* width: 100%; */
  width: 780px;
  background-color: var(--primary-accent-color);
  padding: 10px;
  white-space: nowrap;
}

.menu {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;

/* width: 100%; */
justify-content: space-between;
}

.menu li {
  display: inline;
}

.menu a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 4px;
}

.menu a:hover {
  background-color: var(--hover-color);
}

/* ==========================
   Responsive Navbar
   ========================== */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: var(--text-color);
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu {
    display: none; 
    flex-direction: column;
    gap: 10px;

    padding: 10px;
    background-color: var(--primary-accent-color);
    border-radius: 8px;
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 1000;

  /* background-color: red !important; */ /* Temporary debug color */
  /* opacity: 0.5; */ /* Temporary transparency */
  }

  .menu.open {
    display: flex;

  /* background-color: green !important; */ /* Indicates the open state */
  }

  .menu-toggle {
    display: block;
    margin-bottom: 10px;
  }

  /* navbar looks better in narrow view */
  .navbar {
    width: 350px;  /* Adjust to fit the viewport */
     /* background-color: #322; */
     background-color: var(--new-background-color);
    }

  .app {
    border-left: none; /* Remove borders for very narrow screens if it looks cramped */
    border-right: none;
  }


}

  /*******************/
 /* Sub-menu styles */
/*******************/
.dropdown {
  position: relative; /* Establishes positioning context for the submenu */
}

.submenu {
  display: none;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  position: absolute;
  background-color: var(--primary-accent-color);
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  top: 100%;
  left: -82px;
  z-index: 1000;
}

.submenu li {
   padding: 5px 10px; 
   margin: 0;
   line-height: 0.2;
}

.submenu a {
  text-decoration: none;
  color: var(--text-color);
  display: block;
}

.submenu a:hover {
  background-color: var(--hover-color);
}

.submenu.open {
  display: block;
}

/* For narrow view */
@media (max-width: 768px) {
  .submenu {
    position: static;
    box-shadow: none;
    border-radius: 0;
  }
}



/* ==========================
   Genre Grid Styles
   ========================== */
.genre-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.genre-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--primary-accent-color);
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.genre-card:hover {
  transform: scale(1.05);
}

.genre-card img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.genre-card h3 {
  margin: 10px 0;
  font-size: 1.2rem;
}

.container {
  max-width: 780px; /* Limit the width to 1200px (or any value you prefer) */
  margin: 0 auto;    /* Center the container horizontally */
}

/* ==========================
   Station Grid Styles
   ========================== */
.station-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px 20px 125px 20px;
}

.station-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--primary-accent-color);
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.station-card:hover {
  transform: scale(1.05);
}

.station-card img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.station-card h3 {
  margin: 10px 0;
  font-size: 1.2rem;
}

/* ==========================
   Audio Player Styles
   ========================== */
.audio-player,
.persistent-player {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 800px;
  background-color: var(--primary-accent-color);
  color: var(--text-color);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;

border: solid #CCC;
border-width: 1px 0px 1px 0px;

}

.audio-player span {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

audio {
  width: 100%;
}

/* ------------
   Back Button
   ------------ */

.back-button-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  height: 5vh;          /* Makes the container full height */
}

.back-button {
  margin-top: 30px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  background-color: #007acc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #005fa3;
}


/* ==========================
   Search Button
   ========================== */
.clear-button {
    margin-left: 24px; /* Adds space on the left */
    background-color: #f5f5f5; /* Optional: Set a background color */
    border: none; /* Optional: Remove borders */
    color: #333; /* Optional: Set text color */
    font-size: 15px; /* Optional: Set font size */
    cursor: pointer; /* Optional: Change the cursor to a pointer */
    box-sizing: border-box; /* Ensures padding doesn't affect button size */
    width: 119px; /* Optional: Set a fixed width */
    height: 40px; /* Optional: Set a fixed height */
}


/* ==========================
   Footer Styles
   ========================== */
/*
footer {
  background-color: var(--primary-color);
  color: var(--text-color);
  text-align: center;
  padding: 10px;
}
*/


