.footer {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 20px 0 125px 0;
  text-align: center;

  margin: 0 auto; 
  margin-top: auto;

  max-width: 800px; /* Ensures full width of footer */
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 15px; /* Spacing between links */
  flex-wrap: wrap; /* Ensures links wrap on smaller screens */
  margin-bottom: 10px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.footer-link:hover {
  color: #ffd700; /* Gold color on hover */
}

.footer-text {
  font-size: 12px;
  margin-top: 10px;
  opacity: 0.8; /* Slightly faded text */
}

