.recent-songs-page {
  margin: 20px auto; /* Center the content with margins */
  padding: 20px 20px 125px 20px;
  max-width: 750px; /* Limit the width for readability */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: Arial, sans-serif; /* Clean font */
}

.recent-songs-page h1 {
  text-align: center; /* Center the title */
  font-size: 1.8rem; /* Adjust title size */
  color: #333; /* Dark text color */
  margin-bottom: 20px; /* Space below the heading */
}

.recent-songs-page ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.recent-songs-page li {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space out song and artist */
  align-items: center; /* Vertically center content */
  padding: 10px 15px; /* Spacing inside list items */
  border-bottom: 1px solid #ddd; /* Divider between items */
}

.recent-songs-page li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.recent-songs-page li strong {
  color: #007acc; /* Highlight song name */
  font-weight: bold; /* Bold the song title */
}

.recent-songs-page li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.recent-songs-page li span {
  font-size: 0.9rem; /* Smaller font for metadata */
  color: #555; /* Subtle color for metadata */
}

