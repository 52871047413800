/* pages styles */
.about-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 750px;
  margin: 30px auto;
  color: #FFF;

  border: 1px solid #ddd;
  border-radius: 10px;
}

.about-page h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #007acc;
}

.about-page h2 {
  font-size: 1.8rem;
  color: #007acc;
  margin-top: 30px;
}

.about-page p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.about-page ul {
  margin-left: 20px;
  list-style: disc;
}

.about-page ul li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.about-page a {
  color: #007acc;
  text-decoration: underline;
}

.about-page a:hover {
  color: #FFF;
}

