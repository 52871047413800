.support-page {
  padding: 20px 20px 125px 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 750px;
  margin: 30px auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.support-page h1 {
  font-size: 2.5rem;
  color: #007acc;
  text-align: center;
  margin-bottom: 20px;
}

.support-page h2 {
  font-size: 1.8rem;
  color: #007acc;
  margin-top: 30px;
}

.support-page p {
  font-size: 1rem;
  color: #FFF;
  margin-bottom: 15px;
}

.support-page ol,
.support-page ul {
  margin-left: 20px;
}

.support-page ol li,
.support-page ul li {
  margin-bottom: 10px;
}

.support-page a {
  color: #007acc;
  text-decoration: underline;
}

