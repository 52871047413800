/*********************************
 Search Bar Styles (Shared)
*********************************/

/* Wrapper for Search Bars (Optional) */
.search-bar, .search-box {
  margin: 20px auto;
  text-align: center;
}

/* Input Field Styling */
.search-input {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  margin: 20px auto; /* Center the input field */
  display: block;
  font-size: 1rem;
  border: 1px solid #ccc; /* Default border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: border-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
}

/* Focus State */
.search-input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #007acc; /* Focus border color */
  box-shadow: 0 0 5px rgba(0, 122, 204, 0.5); /* Focus glow effect */
}

/* Placeholder Text (Optional Styling) */
.search-input::placeholder {
  color: #888; /* Muted placeholder color */
  font-style: italic; /* Optional italic styling */
}

/* Disabled State */
.search-input:disabled {
  background-color: #f9f9f9; /* Light gray background */
  border-color: #ddd; /* Neutral border */
  color: #aaa; /* Muted text color */
  cursor: not-allowed; /* Disabled cursor */
}

